import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
function Merchants_Navbar({location}) {
    const locationRef = useRef(null)
    const scrollLeft = () => {
        locationRef.current.scrollLeft += -1000;
    };
    const scrollRight = () => {
        locationRef.current.scrollLeft += 1000;
    };
    useEffect(()=>{
        switch(location){
            case 'terminals':
            case 'gateways':
            case 'pos-solutions':
            case 'services':
                scrollRight()
                break
            default:
                scrollLeft()
        }         
    },[])
    return (
        <div>
            <nav className="cw-bg-secondary w-100  ">
                <div className="sm:flex sm:justify-center flex justify-between relative">
                    <FontAwesomeIcon onClick={scrollLeft} className="cursor-pointer my-auto py-3 px-3 text-white text-xs block sm:hidden" icon={faChevronLeft}/>
                    <div style={{scrollBehavior:'smooth'}} ref={locationRef} className="overflow-auto scrollbar-hide whitespace-nowrap">
                        <ul className="inline-flex space-x-2 mx-auto py-2 text-sm font-semibold text-center text-white">
                            <Link to='/merchants/'><li className={`${location === 'index' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>Doing More for Merchants</li></Link>
                            <Link to='/merchants/cwa-gateway/'><li className={`${location === 'cwa-gateway' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>CWA Gateway</li></Link>
                            <Link to="/merchants/services/"><li className={`${location === 'services' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>Services</li></Link>
                            <Link to="/merchants/terminals/"><li className={`${location === 'terminals' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>Terminals</li></Link>
                            <Link to="/merchants/gateways/"><li className={`${location === 'gateways' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>Gateways</li></Link>
                            <Link  to="/merchants/pos-solutions/"><li className={`${location === 'pos-solutions' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>POS Systems</li></Link>
                        </ul>
                    </div>
                    <FontAwesomeIcon onClick={scrollRight} className="cursor-pointer my-auto py-3 px-3 text-white text-xs block sm:hidden" icon={faChevronRight}/>
                </div>    
            </nav>
        </div>
    );

    // return (
    //     <div>
    //         <nav className="cw-bg-secondary w-100 px-5 ">
    //             <div className="sm:flex sm:justify-center ">
    //                 <div className="overflow-auto scrollbar-hide whitespace-nowrap">
    //                     <ul className="inline-flex space-x-2 mx-auto py-2 text-sm font-semibold text-center text-white">
    //                         <Link to='/merchants/'><li ref={location === 'index' ? locationRef:null} className={`${location === 'index' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>Doing More for Merchants</li></Link>
    //                         <Link to='/merchants/cwa-gateway/'><li ref={location === 'cwa-gateway' ? locationRef:null} className={`${location === 'cwa-gateway' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>CWA Gateway</li></Link>
    //                         <Link to="/merchants/services/"><li ref={location === 'services' ? locationRef:null} className={`${location === 'services' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>Services</li></Link>
    //                         <Link to="/merchants/terminals/"><li ref={location === 'terminals' ? locationRef:null} className={`${location === 'terminals' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>Terminals</li></Link>
    //                         <Link to="/merchants/gateways/"><li ref={location === 'gateways' ? locationRef:null} className={`${location === 'gateways' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>Gateways</li></Link>
    //                         <Link  to="/merchants/pos-solutions/"><li ref={location === 'pos-solutions' ? locationRef:null} className={`${location === 'pos-solutions' ? 'cw-bg-lightblue':null} p-1 rounded cursor-pointer`}>POS Systems</li></Link>
    //                     </ul>
    //                 </div>
    //             </div>    
    //         </nav>
    //     </div>
    // );
}

export default Merchants_Navbar;