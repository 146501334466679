import React from 'react';
import Layout from '../../../components/Layout';
import Merchants_Navbar from '../../../components/Merchants_Navbar';

function Index(props) {
    return (
        <div>
            <Layout location={'Merchants'} title={'Services'}>
            <Merchants_Navbar location={'services'}/>
            <section className="cw-bg-bluegradient text-center py-10 px-5">
                <div className="text-center w-100">
                    <h1 className="text-5xl text-white font-bold pb-2">Services</h1>
                </div>
            </section>
            <section className='py-20'>
                <div className="max-w-3xl mx-auto px-5 text-center">
                    <div className='mb-7'>
                        <h2 className="text-3xl cw-text-lightblue font-bold mb-3">Delivering financial support to grow your business</h2>
                        <p className="font-serif text-xl leading-8">
                            It's no secret that it takes money to make money. This rings especially true for entrepreneurs and small business owners. 
                            Whether you're looking to expand, renovate, purchase inventory or meet growing payroll needs, we make securing a term loan 
                            a fast and straightforward process. The application takes minutes to complete — and you can receive your loan in as little 
                            as 24-48 hours.
                        </p>
                    </div>
                </div>
            </section> 
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650463265/cwa/assets/images/Merchants/Services/merchant-investing1_fub9is.png"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="cw-text-lightblue font-bold text-lg">Investing in your success</h2>
                            <p className="py-4 font-serif">
                                Securing a loan isn't the only way to invest in your business. Sometimes a simple cash advance is all you need to take advantage 
                                of growth opportunities. Our cash advance program provides you with working capital against future credit sales —so all you have 
                                to do is business as usual and repaying the advance will take care of itself
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 cw-bg-lightblue">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="text-white font-bold text-lg">Enjoy faster access to cash</h2>
                            <p className="text-white py-4 font-serif">
                                Next Day Funding means improved cash flow. Improved cash flow means greater flexibility when addressing essential business operations 
                                — like taking care of payroll, purchasing inventory and paying bills. And while most processors will hold your funds for a period of 
                                two to three days, we deliver them to your account the next day at no additional cost to you.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650463516/cwa/assets/images/Merchants/Services/merchant-nextday1_zvo3ob.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650463692/cwa/assets/images/Merchants/Services/merchant-support2_yolxwo.png"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="cw-text-lightblue font-bold text-lg">Exceptional customer service and support — any time you need it</h2>
                            <p className="py-4 font-serif">
                                Our commitment to your success goes beyond providing you with the best products and solutions. Every CWA contract comes with 
                                total access to our around-the-clock customer service and technical support. Because as a business owner, you already have enough 
                                to do without also having to worry about your payment processing.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            {/* <section className="cw-bg-bluegradient">
                <div className="px-5 text-center lg:text-left flex lg:justify-between py-5 align-center max-w-5xl mx-auto space-y-3">
                    <h3 className="text-white text-xl font-serif max-w-xl leading-8">Do you know a business owner who can benefit from our services?</h3>
                    <button className="transition ease-in-out delay-150 border-2 border-white p-2 text-white hover:bg-white hover:text-green-400 font-semibold text-lg">Learn More About Referring Merchants</button>
                </div>
            </section> */}
            
            </Layout>
            
        </div>
    );
}

export default Index;